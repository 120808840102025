import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FormFields from '../fields/FormFields';
import i18n from '../translations';
import { hasRequiredData, validateListItems } from '../utils';
import { createUniqueId } from '../utils/objectUtils';
import InputBlocker from './InputBlocker';
import { replaceObjectValues, replaceValues } from './utils';

const rewriteCondition = (name, index, condition) => {
  if (!condition) {
    return condition;
  }
  if (condition.op === 'and' || condition.op === 'or') {
    return {
      ...condition,
      expressions: condition.expressions.map((e) => rewriteCondition(name, index, e)),
    };
  }

  return {
    ...condition,
    key: `${name}[${index}][${condition.key}]`,
  };
};

const mayRewriteCondition = (name, index, condition) => {
  if (!condition || condition.version !== '2') {
    return condition;
  }

  return rewriteCondition(name, index, condition);
};

function getItemFields(fields, name, index) {
  function prefixName(fieldName) {
    if (!fieldName) return fieldName;
    return `${name}[${index}][${fieldName}]`;
  }

  const itemFields = fields.map((field) => ({
    ...field,
    // TODO: broken, doesn't work for all conditions
    condition: field.condition ? mayRewriteCondition(name, index, field.condition) : undefined,
    parentCondition: field.parentCondition ?? undefined,
    fields: field.fields ? getItemFields(field.fields, name, index) : undefined,
    checkInField: prefixName(field.checkInField),
    checkOutField: prefixName(field.checkOutField),
    name: field.type !== 'Fields' && field.type !== 'Column' && field.type !== 'Columns' && field.type !== 'Tab' ? prefixName(field.name) : '',
  }));
  return itemFields;
}

function getItemLabel(itemLabel, count = 0) {
  const single = count === 1;
  return replaceValues(`{itemLabel.toLowerCase}${single ? '' : 's'}`, { itemLabel });
}

export const ListItemSummary = (props) => {
  const { data, itemLabel, name, mode, fields = [] } = props;
  const items = get(data, name, []);
  if (!Array.isArray(items)) {
    console.warn('List item is not an array !!', items);
    return null;
  }
  return (
    <ul className="ItemList__summary">
      {items.map((item, index) => (
        <li key={item._id}>
          <h3>
            {itemLabel} {index + 1}
          </h3>
          <FormFields mode={mode} fields={getItemFields(fields, name, index)} data={data} />
        </li>
      ))}
    </ul>
  );
};

class Accordion extends PureComponent {
  static defaultProps = {
    defaultOpen: false,
  };
  state = {
    open: this.props.defaultOpen,
  };

  render() {
    const { domRef, header, isOpen, children, onToggle, isIncomplete } = this.props;
    return (
      <div ref={domRef} className={cx('af-team__accordion', { incomplete: isIncomplete })}>
        <div style={{ display: 'table-cell', verticalAlign: 'top' }}>
          <div className="af-team__accordion__header" onClick={onToggle} style={{ cursor: 'pointer' }}>
            {header}
          </div>
          <div className="af-team__accordion__content" style={{ display: isOpen ? 'block' : 'none' }}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

function computeMinMaxCount(value, data) {
  if (typeof value === 'string') {
    const n = Number(replaceValues(value, data));
    if (Number.isNaN(n)) return 0;
    return n;
  }
  return value;
}

function hasDynamicCount(maxItems) {
  return maxItems && typeof maxItems === 'string' && maxItems[0] === '{';
}

export default class ListItem extends PureComponent {
  state = {
    openedIndex: undefined,
  };

  openedElement = React.createRef();

  getItems() {
    const { data, name } = this.props;
    return data[name] || [];
  }

  getMinMaxCount(value) {
    const { data } = this.props;
    return computeMinMaxCount(value, data);
  }

  componentDidMount() {
    const {
      valueIfEmpty,
      data,
      onChange,
      name,
      // maxLabel = "Vous ne pouvez plus rajouter d'entrées",
    } = this.props;
    // Init ?
    const minItems = this.getMinMaxCount(this.props.minItems);
    const items = this.getItems();
    if (minItems && items.length == 0 && valueIfEmpty) {
      onChange(
        name,
        valueIfEmpty.map((item) => replaceObjectValues(item, data)),
      );
      this.setState({
        openedIndex: 0,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data, name, onChange } = nextProps;
    const items = data[name] || [];
    const maxItems = computeMinMaxCount(nextProps.maxItems, data);
    if ((maxItems || hasDynamicCount(nextProps.maxItems)) && items.length > maxItems) {
      onChange(name, items.slice(0, maxItems || 0)); // Limit length !
    }
    // TODO : add item if missing ? Don't mess with componentDidMount though...
  }

  handleAddItem = () => {
    const { defaultAddValue, valueIfEmpty, data, onChange, name, maxLabel } = this.props;
    const finalDefaultAddValue = replaceObjectValues(defaultAddValue, data);
    const items = this.getItems();
    const maxItems = this.getMinMaxCount(this.props.maxItems);
    if (items.length === 0 && valueIfEmpty) {
      onChange(
        name,
        valueIfEmpty.map((item) => ({ editorKey: createUniqueId(), ...replaceObjectValues(item, data) })),
      );
      this.setState({ openedIndex: 0 });
    } else {
      if (!maxItems) {
        onChange(name, [...items, { editorKey: createUniqueId(), ...finalDefaultAddValue }]);
      } else {
        if (items.length < maxItems) {
          onChange(name, [...items, { editorKey: createUniqueId(), ...finalDefaultAddValue }]);
        } else {
          alert(maxLabel);
        }
      }
      this.setState(
        {
          openedIndex: items.length, // Open new one
        },
        this.scrollToOpenElement,
      );
    }
  };

  scrollToOpenElement = () => {
    // Scroll to top of item
    const el = this.openedElement.current;
    if (el && el.scrollIntoView) {
      el.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }
  };

  handleDelete = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const { onChange, name, deleteText } = this.props;
    const items = this.getItems().slice(0);
    if (window.confirm(deleteText)) {
      items.splice(index, 1);
      onChange(name, items);
    }
  };

  handleToggle = (index) => {
    const { openedIndex } = this.state;
    if (openedIndex === index) {
      this.setState({ openedIndex: undefined });
    } else {
      this.setState({ openedIndex: index }, this.scrollToOpenElement);
    }
  };

  render() {
    const {
      mode,
      summary,
      strings,
      name,
      fields,
      data: parentData,
      emptyLabel,
      itemLabel,
      alwaysOpen,
      itemHeaderDetails,
      onChange,
      lockList: propsLockList,
      registrationErrors,
    } = this.props;
    const items = this.getItems();
    const { openedIndex } = this.state;
    const maxItems = this.getMinMaxCount(this.props.maxItems);
    const minItems = this.getMinMaxCount(this.props.minItems);
    const lockList = propsLockList || mode === 'summary';

    const isValid = validateListItems(this.props, items, parentData);
    // const data = parentData;

    if (!maxItems && hasDynamicCount(this.props.maxItems)) {
      return null; // Hide for now...
    }

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return <ListItemSummary label={summary?.label} {...this.props} />;
    }

    return (
      <div className={cx('af-field-container--ListItem', name)}>
        {!!maxItems && (
          <h4 style={{ margin: 0 }} className="af-maxItems">
            {replaceValues(get(strings, 'max-items-info', 'MAX : {maxItems} {itemLabel.toLowerCase}'), {
              maxItems,
              itemLabel: getItemLabel(itemLabel, maxItems),
            })}
          </h4>
        )}
        <div className="ListItem__header" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <h3 className="ListItem__count" style={{ flex: 1 }}>
            {items.length} {getItemLabel(itemLabel, items.length)}
          </h3>
          {mode !== 'summary' && this.renderButton(items)}
        </div>
        {items.map((m, index) => {
          const headerTitle = replaceValues(itemHeaderDetails, m);
          const hasInfo = !!headerTitle.trim();
          const isOpen = alwaysOpen || openedIndex === index;
          const isIncomplete = !isOpen && !hasRequiredData(fields, m, parentData);
          const itemFields = getItemFields(fields, name, index);
          const item = get(parentData, name)?.[index];
          const data = { ...parentData, workshopsRegistrations: item?.workshopsRegistrations };

          const handleChange = (key, value) => {
            if (key === 'workshopsRegistrations') {
              // Store on this user
              onChange(`${name}[${index}].workshopsRegistrations`, value);
            } else {
              onChange(key, value);
            }
          };

          return (
            <Accordion
              key={item._id || item.editorKey || index}
              style={{ margin: 0 }}
              isOpen={isOpen}
              domRef={isOpen ? this.openedElement : undefined}
              isIncomplete={isIncomplete}
              header={
                <div className="af-team__header">
                  <h3 style={{ margin: 0, flex: 1 }}>
                    <span style={{ display: 'inline-block', width: 20, textAlign: 'right' }}>{index + 1}.</span>
                    {hasInfo ? ` ${headerTitle}` : ` ${emptyLabel || ''} ${index + 1}`}
                  </h3>
                  {isIncomplete && <span className="incomplete">{i18n.t('errors.incomplete')}</span>}
                  {!lockList && <i className="fa fa-trash" style={{ marginRight: 10 }} onClick={(e) => this.handleDelete(e, index)} />}
                  <i className={`fa fa-chevron-${isOpen ? 'down' : 'right'}`} />
                </div>
              }
              onToggle={() => this.handleToggle(index)}
            >
              <FormFields registrationErrors={registrationErrors} mode={mode} fields={itemFields} data={data} onChange={handleChange} />
            </Accordion>
          );
        })}
        {items.length > 0 && <div style={{ marginTop: 16 }}>{this.renderButton(items)}</div>}
        {!!minItems && items.length < minItems && (
          <div className="af-message af-message--error">
            {replaceValues(
              get(strings, 'min-items-not-reached', `Vous devez avoir au moins {minItems} {itemLabel.toLowerCase}${minItems === 1 ? '' : 's'}`),
              {
                minItems,
                itemLabel,
              },
            )}
            <InputBlocker name={name} />
          </div>
        )}
        {!!maxItems && items.length > maxItems && (
          <div className="af-message af-message--error">
            {replaceValues(
              get(strings, 'max-items-reached', `Vous devez avoir moins de {maxItems} {itemLabel.toLowerCase}${maxItems === 1 ? '' : 's'}`),
              {
                minItems,
                itemLabel,
              },
            )}
            <InputBlocker name={name} />
          </div>
        )}
        {!isValid && (
          <div className="af-message af-message--error required-fields" style={{ marginTop: 0 }}>
            {i18n.t('errors.fill-all-required-fields')}
            <InputBlocker name={name} />
          </div>
        )}
      </div>
    );
  }

  renderButton(items) {
    const { itemLabel, buttonText, lockList } = this.props;
    if (lockList) return null; // Closed...
    const maxItems = this.getMinMaxCount(this.props.maxItems);
    if (maxItems && items.length >= maxItems) return null; // Can't add !
    return (
      <button type="button" className="af-team__button" onClick={this.handleAddItem}>
        <i className="fa fa-plus" style={{ marginRight: 5 }} />
        {replaceValues(buttonText, { itemLabel })}
      </button>
    );
  }
}

ListItem.defaultProps = {
  alwaysOpen: false,
  buttonText: 'Ajouter',
  defaultAddValue: {},
  deleteText: 'Etes-vous sûr de vouloir supprimer cette entrée ?',
  fields: [],
  itemHeaderDetails: '{firstName} {lastName}',
  itemLabel: 'entrée',
  lockList: false,
  maxItems: undefined,
  maxLabel: "Vous ne pouvez plus rajouter d'entrées",
  minItems: undefined,
  strings: {},
  summary: undefined,
};

ListItem.propTypes = {
  alwaysOpen: PropTypes.bool,
  buttonText: PropTypes.string,
  data: PropTypes.object.isRequired,
  defaultAddValue: PropTypes.object,
  deleteText: PropTypes.string,
  emptyLabel: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  itemLabel: PropTypes.string,
  itemHeaderDetails: PropTypes.string,
  lockList: PropTypes.bool,
  maxItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLabel: PropTypes.string,
  minItems: PropTypes.number,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  strings: PropTypes.object,
  summary: PropTypes.object,
  valueIfEmpty: PropTypes.array,
};
