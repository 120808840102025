/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import cx from 'classnames';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import React, { useMemo } from 'react';
import Quota from '../../components/Quota';
import { QuotaMode } from '../../components/Quota/Quota';
import { replaceValues } from '../../components/utils';
import i18n from '../../translations';
import { findOverlappingSession } from '../../utils/sessionUtils';
import { Error, Errors } from '../errors';
import { FieldProps } from '../field.types';
import './WorkshopRegistrationV2.scss';
import { Workshop, WorkshopRegistration } from './workshop.types';

enum WorkshopGroupBy {
  DATE = 'date',
}

type WorkshopRegistrationUIProps = {
  columns?: number;
  showDateOnly: boolean;
  enableRadio?: boolean;
  showCategory?: boolean;
};

type WorkshopRegisrationStrings = {
  'fullDateFormat'?: string;
  'startDateFormat'?: string;
  'max-count-reached'?: string;
};

type Data = {
  workshopsRegistrations: WorkshopRegistration[];
} & Record<string, any>;

type WorkshopRegistrationV2Props = FieldProps<any> & {
  ui?: WorkshopRegistrationUIProps;
  strings?: WorkshopRegisrationStrings;
  workshops: Workshop[];
  data: Data;
  group?: WorkshopGroupBy;
  registrationErrors?: any[];
  quotaMode?: QuotaMode;
} & Record<string, any>;

function getWorkshopDate(startDate: string, endDate: string, strings: WorkshopRegisrationStrings = {}, options: { group?: WorkshopGroupBy }) {
  const { group } = options;
  const groupByDate = group === WorkshopGroupBy.DATE;
  const {
    fullDateFormat = groupByDate ? 'workshop-registrations.date-time-only' : 'workshop-registrations.date-time',
    startDateFormat = groupByDate ? 'workshop-registrations.start-time-only' : 'workshop-registrations.start-time',
  } = strings;
  const dateFormat = !!endDate ? fullDateFormat : startDateFormat;
  return i18n.t(dateFormat, { startDate, endDate });
}

function renderGroupedWorkshops(workshops: Workshop[], renderer: (w: Workshop) => any) {
  const workshopsByDate = groupBy(workshops, (w) => moment(w.startDate).format('YYYY-MM-DD'));
  const dates = Object.keys(workshopsByDate).sort();
  return (
    <>
      {dates.map((date) => (
        <div className="WorkshopRegistrationV2__Date" key={date}>
          <h4>{moment(date, 'YYYY-MM-DD').format('LL')}</h4>
          {workshopsByDate[date].map(renderer)}
        </div>
      ))}
    </>
  );
}

export const WorkshopsSummary = (props: WorkshopRegistrationV2Props & { workshops: Workshop[] }) => {
  const { label, style, summary, workshops, data, strings, group } = props;
  const { workshopsRegistrations = [] } = data;

  const groupByDate = group === WorkshopGroupBy.DATE;

  const checkedWorkshops = workshops.filter((workshop) => {
    const isChecked = workshopsRegistrations.some((ws) => ws.sessionId === workshop._id);
    return isChecked;
  });

  if (!checkedWorkshops.length) return null;

  const renderWorkshop = (workshop: Workshop) => {
    const { _id, endDate, sessionCategory, startDate, title } = workshop;
    const date = getWorkshopDate(startDate, endDate, strings, { group });
    return (
      <label key={_id} className="session">
        <div style={{ width: '100%' }}>
          <span>{title}</span> {'   '}
          <span className="date">({date})</span>
          {sessionCategory && <span className="category-tag">{sessionCategory}</span>}
        </div>
      </label>
    );
  };

  return (
    <div className="af-field-container--WorkshopRegistration" style={{ ...style }}>
      <div className="label">{summary?.label || label}</div>
      <div className="list">
        {!groupByDate && checkedWorkshops.map(renderWorkshop)}
        {groupByDate && renderGroupedWorkshops(checkedWorkshops, renderWorkshop)}
      </div>
    </div>
  );
};

const { workshops: allWorkshops } = window.__DATA__ as { workshops: Workshop[] };

const WorkshopRegistrationV2 = (props: WorkshopRegistrationV2Props) => {
  const {
    data,
    description,
    label,
    minCount,
    mode,
    maxCount,
    onChange,
    showShortDescription = true,
    strings = {},
    style,
    summary,
    workshops,
    registrationErrors,
    allowOverlappingSessions = false,
    allowDuplicateWorkshops = false,
    forcedUserCount,
    quotaMode,
    group,
    ui,
    disabled: fieldDisabled,
  } = props;

  const groupByDate = group === WorkshopGroupBy.DATE;
  const { columns, showDateOnly, enableRadio, showCategory = false } = ui || {};

  const { workshopsRegistrations = [], userCount = 1 } = data;
  const workshopsById = useMemo(() => keyBy(workshops, '_id'), [workshops]);
  const fieldWorkshopRegistrations = useMemo(
    () => workshopsRegistrations?.filter((w) => w.sessionId in workshopsById),
    [workshopsRegistrations, workshopsById],
  );
  const registeredSessionsById = keyBy(fieldWorkshopRegistrations, 'sessionId');
  const registeredWorkshopsById = keyBy(fieldWorkshopRegistrations, (ws) => workshopsById[ws.sessionId]?.workshopId);
  const registeredSessions = workshops?.filter((session) => session._id in registeredSessionsById);

  const allRegisteredSessions = useMemo(() => {
    const allRegisteredWorkshopsById = keyBy(workshopsRegistrations, 'sessionId');
    return allWorkshops?.filter((session) => session._id in allRegisteredWorkshopsById);
  }, [allWorkshops, workshopsRegistrations]);

  const registrationCount = fieldWorkshopRegistrations.length;
  const hasMaxCount = maxCount && registrationCount >= maxCount;

  const inputMode = minCount === 1 && maxCount === 1 && enableRadio ? 'radio' : 'checkbox';

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { checked = false, name } = target || {};

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const checkedWorkshop = workshops.find((ws) => ws._id === name)!;
    if (checked) {
      if (!hasMaxCount) {
        const newWorkshopRegistrations = [
          ...workshopsRegistrations,
          {
            sessionId: checkedWorkshop._id,
            collection: checkedWorkshop.collection,
          },
        ];
        onChange('workshopsRegistrations', newWorkshopRegistrations);
      }
    } else {
      const newWorkshopRegistrations = workshopsRegistrations.filter((ws) => ws.sessionId !== checkedWorkshop._id);
      onChange('workshopsRegistrations', newWorkshopRegistrations);
    }
  };

  const handleRadioChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const { value } = target || {};

    // Uncheck all and check new one
    const [checkedWorkshop, otherWorkshops] = partition(workshops, (ws) => ws._id === value);
    const byKey = keyBy(otherWorkshops, '_id');

    const newRegistrations = workshopsRegistrations.filter((ws) => !(ws.sessionId in byKey));
    if (checkedWorkshop.length) {
      const { _id: sessionId, collection } = checkedWorkshop[0];
      newRegistrations.push({ sessionId, collection });
      onChange('workshopsRegistrations', newRegistrations);
    }
  };

  const { errors } = WorkshopRegistrationV2.validator(props, data);
  if (mode === 'summary') {
    if (summary?.hidden) {
      return null;
    }
    return <WorkshopsSummary {...props} />;
  }

  const renderWorkshop = (workshop: Workshop) => {
    const { _id, endDate, startDate, title, category, sessionCategory, shortDescription } = workshop;
    const registrationError = registrationErrors?.find((error) => error.sessionId === _id);
    const registrationsCount = registrationError?.registrationsCount || workshop?.usersCount || 0;
    const sessionQuota = registrationError?.sessionQuota || workshop?.sessionQuota;
    const remainingPlaces = sessionQuota - registrationsCount;
    const workshopFull = !!sessionQuota && (forcedUserCount || userCount) > remainingPlaces;
    const isChecked = workshopsRegistrations.some((ws) => ws.sessionId === _id);
    const hasOtherWorkshop = !isChecked && workshop.workshopId && registeredWorkshopsById[workshop.workshopId];
    const date = getWorkshopDate(startDate, endDate, strings, { group });
    const isDisabled =
      !isChecked &&
      (workshopFull ||
        (hasOtherWorkshop && !allowDuplicateWorkshops) ||
        (hasMaxCount && inputMode !== 'radio' && !(workshop._id in registeredSessionsById)) ||
        (!allowOverlappingSessions && findOverlappingSession(workshop, allWorkshops ? allRegisteredSessions : registeredSessions)));

    return (
      <label key={_id} style={{ opacity: (fieldDisabled && !isChecked) || isDisabled ? 0.5 : 1 }} className={cx('session', _id)}>
        {inputMode === 'checkbox' && (
          <input type="checkbox" name={_id} disabled={fieldDisabled || isDisabled} checked={isChecked} onChange={handleCheckboxChange} />
        )}
        {inputMode === 'radio' && (
          <input type="radio" name={_id} value={_id} disabled={fieldDisabled || isDisabled} checked={isChecked} onChange={handleRadioChange} />
        )}
        <div style={{ width: '100%' }}>
          <div>
            {!showDateOnly && (
              <>
                <span>{title}</span>
                <Quota roomLeft={remainingPlaces} quota={sessionQuota} strings={strings} usePercentages={false} quotaMode={quotaMode} />
              </>
            )}
            {sessionCategory && <span className="category-tag">{sessionCategory}</span>}
            {category && showCategory && <div className="category">{category}</div>}
          </div>
          {/* TODO: ajouter le retour de shortDescription dans registration service V2 */}
          <div className="date">{date}</div>
          {showDateOnly && <Quota roomLeft={remainingPlaces} quota={sessionQuota} strings={strings} usePercentages={false} quotaMode={quotaMode} />}
          {showShortDescription && shortDescription && <div className="description">{shortDescription}</div>}
        </div>
      </label>
    );
  };

  return (
    <div className={cx('af-field-container--WorkshopRegistration', { 'is-disabled': fieldDisabled })} style={{ ...style }}>
      <div className="label">{label}</div>
      <div className="description">{replaceValues(description, { minCount, maxCount })}</div>
      <div className={cx('list', columns && `list--columns-${columns}`, showDateOnly && 'list--showDateOnly')}>
        {!groupByDate && workshops.map(renderWorkshop)}
        {groupByDate && renderGroupedWorkshops(workshops, renderWorkshop)}
        {maxCount && hasMaxCount && <Error message={strings['max-count-reached'] || 'Nombre de workshop maximum atteint'} />}
        <Errors name="workshops" errors={errors} />
      </div>
    </div>
  );
};

WorkshopRegistrationV2.defaultProps = {
  label: undefined,
  style: undefined,
  strings: {},
  workshops: [],
  minCount: undefined,
  maxCount: undefined,
  showShortDescription: true,
  registrationErrors: [],
  description: undefined,
  allowOverlappingSessions: false,
};

WorkshopRegistrationV2.validator = (field: any, data: Data) => {
  const { minCount, maxCount, strings, workshops } = field;
  const { workshopsRegistrations = [] } = data;

  const workshopsById = keyBy(workshops, '_id');
  const fieldWorkshopRegistrations = workshopsRegistrations.filter((w) => w.sessionId in workshopsById);

  const errors = [
    minCount &&
      fieldWorkshopRegistrations.length < minCount && {
        key: 'registration:minCount',
        message: strings['not-enough-workshop'] || "Vous n'avez pas sélectionné assez de workshops",
      },
    maxCount &&
      fieldWorkshopRegistrations.length > maxCount && {
        key: 'registration:maxCount',
        message: strings['max-count-reached'] || 'Nombre de workshop maximum dépassé',
      },
  ].filter((v) => v);

  return {
    valid: errors.length === 0,
    errors,
  };
};

export default WorkshopRegistrationV2;
