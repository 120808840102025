import cx from 'classnames';
import React, { PureComponent } from 'react';
import FieldContainer from '../components/FieldContainer';
import Select from '../components/FixedRequiredSelect';
import StringSummary from '../components/StringSummary';
import { isFieldLocked } from '../components/utils';
import { filterOptions } from '../utils/fieldUtils';
import { ReactComponent as AngleDownIcon } from './../assets/angle-down.svg';
import { valueOf } from './MultiSelectField';
import './select-field.scss';

const SelectArrow = () => (
  <div className="Select__IndicatorContainer">
    <span className="Select__IndicatorBar"></span>
    <div aria-hidden="true" className="Select__IndicatorArrow">
      <AngleDownIcon />
    </div>
  </div>
);

export default class SelectField extends PureComponent {
  render() {
    const {
      data,
      label,
      name,
      options,
      value,
      onChange,
      required = false,
      summary,
      mode,
      locked,
      placeholder,
      disabled,
      variant = 'classic',
    } = this.props;
    const visibleOptions = filterOptions(data, options);
    const isLocked = isFieldLocked(name, locked);
    const isInvalidValue = value && !visibleOptions.some((o) => o.value === value);
    if (mode === 'summary') {
      const labelValue = options.find((o) => o.value === value)?.label;
      return <StringSummary label={label} value={labelValue} summary={summary} />;
    }
    if (variant === 'searchable') {
      const finalValue = options.find((o) => o.value === value);
      return (
        <FieldContainer {...this.props}>
          <Select
            className={cx('af-select-field', name, { 'is-active': value })}
            value={finalValue || ''}
            required={required}
            placeholder={placeholder || label}
            onChange={(v) => onChange(name, valueOf(v))}
            options={visibleOptions}
            isSearchable
            isDisabled={disabled || isLocked}
          />
        </FieldContainer>
      );
    }
    return (
      <FieldContainer {...this.props}>
        <select
          name={name}
          required={required}
          onChange={(e) => onChange(name, e.target.value)}
          value={value || ''}
          className={cx('af-select-field', { 'is-active': value })}
        >
          {/* Auto-hide empty option if another value is selected */}
          {(!value || !required) && (
            <option value="" disabled={disabled || isLocked || required} hidden={required} selected={!value && required}></option>
          )}
          {isInvalidValue && required && <option value="" disabled={disabled || isLocked} selected={isInvalidValue && required}></option>}
          {visibleOptions.map((option) => {
            const { value, label } = option;
            return (
              <option key={value} value={value} disabled={disabled || isLocked}>
                {label}
              </option>
            );
          })}
        </select>
        <SelectArrow />
      </FieldContainer>
    );
  }
}
